import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import { InView } from 'react-intersection-observer'

import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import Seo from 'src/components/global/Seo.js'
import Img from 'gatsby-image'
import typographySizes from 'src/assets/styles/typographySizes'
import Tilt from 'react-parallax-tilt'
import distances from 'src/assets/styles/distances.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Div100vh from 'react-div-100vh'
const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;
`

const Header = styled.header`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
  }
`
const TextBoxWrapper = styled.div`
  position: relative;
  left: 20rem;
  z-index: 5;
  padding-left: ${distances.barW * 2}rem;
  margin-left: 20rem;
  max-width: 250rem;

  ::before {
    content: '';
    width: ${distances.barW}rem;
    height: calc(100% + ${distances.barW}rem);
    background-color: ${colors.white};
    position: absolute;
    left: -0rem;
    top: -${distances.barW / 2}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: 15rem;
  }
`

const Title = styled.h1`
  font-size: 16rem;
  font-weight: 700;
  color: ${colors.white};
  text-transform: uppercase;
  margin-bottom: 10rem;
`

const SubTitle = styled.h2`
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  color: ${colors.white};
  text-transform: uppercase;
  line-height: 1.3;
`
const BottomBarWrapper = styled.div`
  position: absolute;
  height: 100%;
  pointer-events: none;
  width: 100vw;
  bottom: 0;
  left: -20rem;
`

const BottomSticky = styled.div`
  width: 100vw;

  /* height: 100rem; */
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 4;
  top: 0rem;

  position: sticky;
  pointer-events: none;
`

const BottomGradient = styled.div`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  height: 94rem;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
`

const StyledDiv100vh = styled(Div100vh)`
  position: relative;
  pointer-events: none;
  /* background-color: red; */
  /* background-color: blue; */
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.mainColor};
  mix-blend-mode: screen;
`
const BoxWrapper = styled.div`
  width: 80%;
  margin: 150rem 10%;
  transform: rotate(-3deg);
  position: relative;
  :nth-of-type(2n) {
    transform: rotate(3deg);
    ${ImageOverlay} {
      mix-blend-mode: multiply;
    }
  }
  :nth-of-type(3n) {
    width: 70%;
    ${ImageOverlay} {
      display: none;
      mix-blend-mode: screen;
    }
  }
  :nth-of-type(6n) {
    margin: 150rem 10% 150rem 20%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50rem 10%;
    :nth-of-type(6n) {
      margin: 50rem 10% 50rem 20%;
    }
  }
`

const ImageWrapperInView = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 1s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const LabelWrapper = styled.div`
  background-color: ${colors.mainColor};
  padding: 8rem;
  display: inline-block;
  max-width: 80%;
  position: relative;
  opacity: 0;

  transform: translateY(20rem) rotate(-3deg);

  span {
    color: ${colors.black};
    font-size: ${typographySizes.s}rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &.show {
    opacity: 1;
    transform: translateY(0) rotate(0);
    transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const SmallText = styled.span`
  background-color: ${colors.white};
  padding: 8rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 80%);
  color: ${colors.black};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  opacity: 0;
  transform: translate(50%, calc(80% + 20rem)) rotate(3deg);

  &.show {
    opacity: 1;
    transform: translate(50%, calc(80%)) rotate(0);
    transition: all 1s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const LeftCol = styled.div`
  width: 30%;

  z-index: 3;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-top: 130rem;
  }
`

const MidCol = styled.div`
  width: 30%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: -50rem;
  }

  z-index: 3;

  ${BoxWrapper} {
    transform: rotate(3deg);
    ${ImageOverlay} {
      display: block;
      mix-blend-mode: multiply;
    }
    :nth-of-type(2n) {
      transform: rotate(-3deg);
      ${ImageOverlay} {
        display: none;
      }
    }
    :nth-of-type(3n) {
      width: 70%;
      ${ImageOverlay} {
        display: block;
        mix-blend-mode: multiply;
      }
    }
    :nth-of-type(6n) {
      margin: 150rem 10% 150rem 20%;
      @media (max-width: ${mediaQuery.tablet}) {
        margin: 50rem 10% 150rem 20%;
      }
    }
  }
`

const RightCol = styled.div`
  width: 30%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: -50rem;
  }
  z-index: 3;
`
const LabelComponent = ({ pos }) => (
  <BoxWrapper>
    <InView threshold={1} triggerOnce>
      {({ inView, ref, entry }) => (
        <LabelWrapper ref={ref} className={inView && 'show'}>
          <span>{pos.Label}</span>
          {pos.Label_SmallText && (
            <SmallText className={inView && 'show'}>
              {pos.Label_SmallText}
            </SmallText>
          )}
        </LabelWrapper>
      )}
    </InView>
  </BoxWrapper>
)

const ImageComponent = ({ pos }) => (
  <BoxWrapper>
    <InView threshold={0} triggerOnce>
      {({ inView, ref, entry }) => (
        <ImageWrapperInView ref={ref} className={inView && 'show'}>
          <Tilt
            tiltMaxAngleX="10"
            tiltMaxAngleY="10"
            reset={false}
            tiltReverse={true}
          >
            <Img fluid={pos.Img.localFile.childImageSharp.fluid} />

            <ImageOverlay />
          </Tilt>
        </ImageWrapperInView>
      )}
    </InView>
  </BoxWrapper>
)

const IndexPage = ({ data }) => {
  const [lDist, setLDist] = useState(0)
  const [rDist, setRDist] = useState(0)
  const [mDist, setMDist] = useState(0)

  const [scrollTop, setScrollTop] = useState(0)
  const [speedL, setSpeedL] = useState(0)
  const [speedM, setSpeedM] = useState(0)
  const [speedR, setSpeedR] = useState(0)

  const [headerH, setheaderH] = useState(null)
  let intViewportWidth = 0
  const leftC = useRef(null)
  const midC = useRef(null)
  const rightC = useRef(null)

  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    intViewportWidth = window.innerWidth
  }
  useEffect(() => {
    if (isBrowser && intViewportWidth > 900) {
      const heightL = leftC.current.offsetHeight
      const heightM = midC.current.offsetHeight
      const heightR = rightC.current.offsetHeight
      let intViewportHeight = 0

      if (isBrowser) {
        intViewportHeight = window.innerHeight
      }

      const difrenceL = heightL - heightM
      const difrenceR = heightR - heightM

      setSpeedL(difrenceL / (heightM - intViewportHeight))
      setSpeedR(difrenceR / (heightM - intViewportHeight))

      setheaderH(heightM)
    }
  }, [])

  useInterval(() => {
    if (isBrowser) {
      if (document.body.getBoundingClientRect().top > scrollTop) {
        setRDist(scrollTop * speedR)
        setLDist(scrollTop * speedL)
        setScrollTop(document.body.getBoundingClientRect().top)
      } else {
        setRDist(scrollTop * speedR)
        setLDist(scrollTop * speedL)

        setScrollTop(document.body.getBoundingClientRect().top)
      }
    }
  }, 1)

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }
  return (
    <>
      <Seo
        title={data.strapiPageHome.SEO ? data.strapiPageHome.SEO.Title : null}
        description={
          data.strapiPageHome.SEO ? data.strapiPageHome.SEO.Description : null
        }
        image={
          data.strapiPageHome.SEO
            ? data.strapiPageHome.SEO.Img.localFile.publicURL
            : null
        }
      />
      <StyledMain>
        <MainWrapper>
          <BottomBarWrapper>
            <BottomSticky>
              <StyledDiv100vh>
                <BottomGradient>
                  <TextBoxWrapper>
                    {' '}
                    <Title>{data.strapiPageHome.Title}</Title>{' '}
                    <SubTitle>{data.strapiPageHome.SubTitle}</SubTitle>
                  </TextBoxWrapper>
                </BottomGradient>
              </StyledDiv100vh>
            </BottomSticky>
          </BottomBarWrapper>
          <Header
            style={{
              height: `${headerH}rem`,
            }}
          >
            <Lines
              leftMargin
              left
              leftCenter
              center
              rightCenter
              right
              rightMargin
            />

            <LeftCol
              ref={leftC}
              style={{
                transform: `translateY( ${lDist}rem)`,
              }}
            >
              {data.strapiPageHome.Content.map((pos, index) => {
                if (index < data.strapiPageHome.Content.length / 3) {
                  if (pos.Img) {
                    return <ImageComponent pos={pos} />
                  }
                  if (pos.Label) {
                    return <LabelComponent pos={pos} />
                  }
                }
              })}
            </LeftCol>
            <MidCol
              ref={midC}
              style={{
                transform: `translateY( ${mDist}rem)`,
              }}
            >
              {data.strapiPageHome.Content.map((pos, index) => {
                if (
                  index > data.strapiPageHome.Content.length / 3 &&
                  index < (data.strapiPageHome.Content.length / 3) * 2 - 1
                ) {
                  if (pos.Img) {
                    return <ImageComponent pos={pos} />
                  }
                  if (pos.Label) {
                    return <LabelComponent pos={pos} />
                  }
                }
              })}
            </MidCol>
            <RightCol
              ref={rightC}
              style={{
                transform: `translateY( ${rDist}rem)`,
              }}
            >
              {data.strapiPageHome.Content.map((pos, index) => {
                if (index > (data.strapiPageHome.Content.length / 3) * 2 - 1) {
                  if (pos.Img) {
                    return <ImageComponent pos={pos} />
                  }
                  if (pos.Label) {
                    return <LabelComponent pos={pos} />
                  }
                }
              })}
            </RightCol>
          </Header>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const IndexQuery = graphql`
  query IndexQuery {
    strapiPageHome {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      SubTitle
      Title
      Content {
        Img {
          localFile {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 700
                grayscale: true
                traceSVG: { background: "#000000", color: "#FF3D00" }
              ) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        Label
        Label_SmallText
      }
    }
  }
`
export default IndexPage
